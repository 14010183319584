export default [
    '#fa6e6e',
    '#fa706a',
    '#fb7365',
    '#fb7661',
    '#fa795c',
    '#f97c58',
    '#f87f54',
    '#f78250',
    '#f6864c',
    '#f48948',
    '#f28d44',
    '#f09041',
    '#ed943d',
    '#ea973a',
    '#e79b37',
    '#e49e34',
    '#e1a232',
    '#dda530',
    '#d9a92f',
    '#d5ac2e',
    '#d1af2d',
    '#ccb32d',
    '#c7b62e',
    '#c2b930',
    '#bdbc32',
    '#b8bf35',
    '#b2c238',
    '#adc53c',
    '#a6c840',
    '#a0cb45',
    '#99ce4a',
    '#92d14f',
    '#8bd355',
    '#83d65b',
    '#7ad861',
    '#71db68',
    '#67dd6f',
    '#5be076',
    '#4de27d',
    '#3ce485',
]