/**
 * NOTES
 * this.data is the full static data
 * in d, this.project = e = {config: collection config, data: static data}
 * in d, this.options = r = collection config -> rankings -> presets
 * 
 */

const defaultPreset = {
    combined: false,
    id: "rarity-tools",
    method: "score",
    name: "rarity.tools experimental",
    normalize: true,
    propWeights: {},
    uniqueness: false,
    weights: false,
}

class Rarity {
    constructor(data, config) {

        this.data = data;
        this.config = config;
        this.options = config.rankings.presets.find(preset => preset.id === config.rankings.defaultPreset) || defaultPreset;
        this.uniquenessRawTotalWeight = 0;
        this.totalWeight = 0;
        this.matches = data.matches;
        this.itemCount = data.items.length

    }

    get uniquenessWeight() {
        throw new Error('UnimplementedError')
    }

    initialScore() {
        throw new Error('UnimplementedError')
    }

    accumulateScore() {
        throw new Error('UnimplementedError')
    }

    finalizeScore() {
        throw new Error('UnimplementedError')
    }

    mergeUniquenessScore() {
        throw new Error('UnimplementedError')
    }

    run(prices) {
        this.prepareWeightsAndPropValueScores();
        return this.calculateItemScores(prices);
    }

    get uniquenessWeight() {
        if (this.options.weights && this.options.propWeights && undefined !== this.options.propWeights.Uniqueness) {
            return this.options.propWeights.Uniqueness
        } else {
            return 1
        }
    }

    prepareWeightsAndPropValueScores() {
        this.totalWeight = 0;
        this.uniquenessRawTotalWeight = 0;

        this.sumWeights(this.data.basePropDefs);
        if (this.data.derivedPropDefs) this.sumWeights(this.data.derivedPropDefs)

        this.updateUniquenessRawTotalWeight();
        if (this.options.uniqueness) this.totalWeight += this.uniquenessWeight;
        // console.log("totalWeight = " + this.totalWeight)

        this.recalcPropValueScores(this.data.basePropDefs);
        if (this.data.derivedPropDefs) this.recalcPropValueScores(this.data.derivedPropDefs);
        if (this.options.uniqueness) this.recalcCombinedScores();
    }

    sumWeights(propDefs) {
        // this.totalWeight = 0;
        let weight;
        for (var property of propDefs) {
            if (property.type != "primaryKey" && property.type != "data") {
                weight = this.getPropWeight(property, this.options, this.matches);
                if (this.options.weights && weight !== undefined) {
                    this.totalWeight += weight
                } else {
                    this.totalWeight++;
                }
            }
        }
    }

    getPropWeight(prop, options, matches) {
        if (prop.isMatch && !matches || options.combined && prop.hasCombined || !options.combined && prop.isCombined) {
            return 0
        } else if (options.propWeights && undefined !== options.propWeights[prop.name] && options.weights && "data" == prop.type && "score" == options.method) {
            return options.propWeights[prop.name]
        } else if ("primaryKey" == prop.type || "data" == prop.type) {
            return 0
        } else if (options.weights && options.propWeights) {
            if (undefined === options.propWeights[prop.name]) {
                return 1
            } else {
                return options.propWeights[prop.name]
            }
        } else {
            return 1
        }
    }

    updateUniquenessRawTotalWeight() {
        // this.uniquenessRawTotalWeight = 0;
        if (this.options.uniqueness) {
            let matchId, weight;
            for (var combination of this.data.combinations) {
                matchId = this.getMatchId(combination);
                weight = this.getCombinationWeight(combination);
        
                this.uniquenessRawTotalWeight += weight;
                this.data.combinedProps[matchId].weight = weight;
            }
        }
    }

    getMatchId(combination) {
        return combination.join('-')
    }

    getMatchValue(item, combination) {
        return combination.map((comb) => item[comb]).join("|")
    }

    getCombinationWeight = (combination) => {
        let weight = 0, totalWeight = 0, propCount = 0
    
        for (var propIdx of combination) {
            weight = this.getPropWeight(this.data.basePropDefs[propIdx], this.options, this.matches);
            if (weight === 0) {
                return 0;
            }
            totalWeight += weight;
            propCount++;
        }
    
        return totalWeight / propCount;
    }

    recalcPropValueScores(propDefs) {
        const valueWeightsEnabled = this.config.rankings.enableValueWeights && this.options.weights && this.options.valueWeights
    
        let weight
        for (var prop of propDefs) {
            if (prop.pvs && prop.pvs.length > 0) {
                for (var pv of prop.pvs) {
                    weight = this.getPropWeight(prop, this.options, this.matches);
                    if (valueWeightsEnabled && this.options.valueWeights[prop.name]) {
                        var m = this.options.valueWeights[prop.name][pv[0]];
                        if (undefined !== m) {
                            weight *= m
                        }
                    }
                    pv[2] = this.calculatePropValueScore(pv[1], prop.pvs.length, weight)
                    pv[3] = 0
                    pv[4] = 0
                }
            }
        }
    }

    recalcCombinedScores() {

        let weight, combinedProp
        for (var propIdx in this.data.combinedProps) {
            combinedProp = this.data.combinedProps[propIdx]
            weight = combinedProp.weight;
            weight *= this.uniquenessWeightMultiplier;
    
            let n = combinedProp.weight ? 1 : 0
            let combinedPropCount = Object.keys(combinedProp).length - n
    
            for (var key in combinedProp) {
                if (key !== 'weight') {
                    let l = combinedProp[key]
                    l[1] = this.calculatePropValueScore(l[0], combinedPropCount, weight)
                }
            }
        }
    
    }

    getPropValueArray(propDef, prop) {

        if (propDef.pvs) {
            try {
                for (var pv of propDef.pvs) {
                    if (pv[0] === prop) {
                        return pv;
                    }
                }
            } catch (e) {
                console.error(e)
            }
        }
    
    }

    calculateItemScores(prices) {
        const statsScore = this.options.name && this.options.name.indexOf("StatsScore") != -1
    
        let score, count = 0
        let item, itemId, itemPrice, itemProp, propValueArray, propDef, propType, propWeight, val, cumVal, propScoreNorms
        let items = Array(this.data.items.length)
        for (var itemIdx in this.data.items) {
            item = this.data.items[itemIdx]
            itemId = item[0]
            itemPrice = itemId in prices && !!prices[itemId][1] && prices[itemId][2] !== 'WETH' ? prices[itemId][1] : NaN
            // if (!itemPrice) continue

            propScoreNorms = new Array(this.data.basePropDefs.length).fill(NaN)
            score = this.initialScore();
            for (var i=1; i<=this.data.basePropDefs.length; i++) {
                itemProp = item[i];
    
                if (i === this.data.basePropDefs.length) {
                    if (this.data.derivedPropDefs) {
                        for (var j=0; j<itemProp.length; j++) {
                            propValueArray = this.getPropValueArray(this.data.derivedPropDefs[j], itemProp[j]);
                            score = this.accumulateScore(score, propValueArray[2]);
                        }
                    }
                } else {
                    propDef = this.data.basePropDefs[i];
                    propType = propDef.type;
    
                    if (propType === "category" || propType === "range") {
                        val = this.data.basePropDefs[i].pvs[itemProp][2];
                        propScoreNorms[i] = val;
                        score = this.accumulateScore(score, val);
                    } else if (propType === "tags") {
                        try {
                            cumVal = 0;
                            for (var tag of itemProp) {
                                val = this.data.basePropDefs[i].pvs[tag][2];
                                cumVal += val;
                                score = this.accumulateScore(score, val);
                            }
                            propScoreNorms[i]  = cumVal
                        } catch (e) {
                            console.error(e)
                        }
                        // Theres some code here for tagnotes. Dunno what that is so screw that
                    } else if (propType === "data" && this.options.weights && this.options.propWeights && this.options.method === "score" && typeof itemProp == "number") {
                        propWeight = this.options.propWeights[propDef.name];
    
                        if (undefined !== propWeight) {
                            if (statsScore) {
                                score += propDef.statDividend / (propDef.maxLevel + 1 - itemProp) * propWeight;
                            } else if (this.config.rankings.squareLevels && this.config.rankings.squareLevels.indexOf(propDef.name) !== -1) {
                                score += itemProp * itemProp * propWeight;
                            } else if (this.config.rankings.invertLevels && this.config.rankings.invertLevels.indexOf(propDef.name) !== -1) {
                                score += (propDef.maxLevel + 1 - itemProp) * propWeight;
                            } else {
                                score += itemProp * propWeight
                            }
                        }
    
                    }
                }
            }
    
            let finalScore = this.finalizeScore(score);

            let propPriceNorm;
            for (var i=1; i<=this.data.basePropDefs.length-1; i++) {
                itemProp = item[i];
                if (!propScoreNorms[i] || !itemPrice) continue

                propPriceNorm = itemPrice * propScoreNorms[i] / finalScore;

                this.data.basePropDefs[i].pvs[itemProp][3] += propPriceNorm;
                this.data.basePropDefs[i].pvs[itemProp][4] += 1;
            }

            if (this.options.uniqueness) {
                let comboScore = this.initialScore();
                let matchId, matchValue;
                try {
                    for (var combination of this.data.combinations) {
                        matchId = this.getMatchId(combination);
                        matchValue = this.getMatchValue(item, combination)
    
                        val = this.data.combinedProps[matchId][matchValue][1];
                        comboScore = this.accumulateScore(comboScore, val);
                    }
                } catch (e) {
                    console.error(e)
                }
                let finalCombinationScore = this.finalizeScore(comboScore)
                finalScore = this.mergeUniquenessScore(finalScore, finalCombinationScore)
            }
    
            items[itemIdx] = {
                id: itemId,
                rarityScore: Math.round(100*finalScore)/100,
                probability: 0,
                price: itemPrice,
            }
        }
        console.log(this.data.basePropDefs)
        return items.sort((a, b) => a.price - b.price);
    }
}

export class V2Rarity extends Rarity {

    get uniquenessWeightMultiplier() {
        return 1
    }
    
    calculatePropValueScore(count, propCount, weight) {
        var n = count / this.itemCount;
        if (this.options.normalize) {
            n = Math.pow(n, 1 / propCount)
        }
        return Math.pow(n, weight)
    }

    initialScore() {
        return 1
    }

    accumulateScore(score, val) {
        return score * val
    }

    finalizeScore(score) {
        return score
    }

    mergeUniquenessScore(score1, score2) {
        return score1 * this.calculatePropValueScore(score2, 1, this.uniquenessWeight)
    }
}

export class AverageRarity extends Rarity {

    get uniquenessWeightMultiplier() {
        return 1
    }

    calculatePropValueScore(count, propCount, weight) {
        let n = count / this.itemCount;
        if (this.options.normalize) {
            n /= propCount
        }
        return (n * weight * weight)
    }

    initialScore() {
        this.count = 0
        return 0;
    }

    accumulateScore(score, val) {
        this.count++;
        return score + val
    }

    finalizeScore(score) {
        return score / this.count
    }

    mergeUniquenessScore(score1, score2) {
        return (score1 * (this.totalWeight - this.uniquenessWeight) + score2 * (this.uniquenessWeight * this.uniquenessWeight)) / this.totalWeight
    }
}

export class ExperimentalRarity extends Rarity {

    get uniquenessWeightMultiplier() {
        return this.uniquenessWeight / this.uniquenessRawTotalWeight
    }

    calculatePropValueScore(count, propCount, weight) {
        if (this.totalWeight == 0) return 0;
        let n = 1 / count;
        if (this.options.normalize) {
            n = 1e6 * n / (this.totalWeight * propCount)
        } else {
            n *= this.itemCount
        }
        return n * weight
    }

    initialScore() {
        return 0
    }

    accumulateScore(score, val) {
        return score + val
    }

    finalizeScore(score) {
        return score
    }

    mergeUniquenessScore(score1, score2) {
        return score1 + score2
    }
}