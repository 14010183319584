import { useEffect, useState } from 'react'

import { useNavigate, createSearchParams } from "react-router-dom";

import styled from "styled-components";

import Select from 'react-select'
import ETH from '../../components/eth/eth'
import logo from '../../nftythrifter_full.svg'

const HomePage = (props) => {

    const {
        collectionsMinimal,
        collections
    } = props
    
    const navigate = useNavigate()

    const [topPriceCollections, setTopPriceCollections] = useState([])
    const [topVolumeCollections, setTopVolumeCollections] = useState([])
    const [topChangeCollections, setTopChangeCollections] = useState([])


    useEffect(() => {
        if (!collections) return

        console.time('collectionSortTimer')
        let priceQueue = [], priceMinMax = 0;
        let changeQueue = [], changeMinMax = 0;
        let volumeQueue = [], volumeMinMax = 0;
        for (var collection of collections) {
            if (collection?.stats?.seven_day_average_price > priceMinMax) {
                priceQueue.push(collection)
                priceQueue.sort((a, b) => a.stats.seven_day_average_price - b.stats.seven_day_average_price)
                if (priceQueue.length > 10) {
                    priceQueue.shift();
                }
                priceMinMax = Math.min(...priceQueue.map(c => c.stats.seven_day_average_price))
            }
            if (collection?.stats?.seven_day_change > changeMinMax) {
                changeQueue.push(collection)
                changeQueue.sort((a, b) => a.stats.seven_day_change - b.stats.seven_day_change)
                if (changeQueue.length > 10) {
                    changeQueue.shift();
                }
                changeMinMax = Math.min(...changeQueue.map(c => c.stats.seven_day_change))
            }
            if (collection?.stats?.seven_day_volume > volumeMinMax) {
                volumeQueue.push(collection)
                volumeQueue.sort((a, b) => a.stats.seven_day_volume - b.stats.seven_day_volume)
                if (volumeQueue.length > 10) {
                    volumeQueue.shift();
                }
                volumeMinMax = Math.min(...volumeQueue.map(c => c.stats.seven_day_volume))
            }
        }
        console.timeEnd('collectionSortTimer')

        setTopPriceCollections(priceQueue.reverse())
        setTopVolumeCollections(volumeQueue.reverse())
        setTopChangeCollections(changeQueue.reverse())        

    }, [collections])

    console.log(topPriceCollections)

    return (
        <Page>
            <Header>
                <Logo src={logo}/>
            </Header>
            <Content>
                <Title>Welcome to nftythrifter!</Title>
                <Description>NftyThrifter is a tool to help find you the best NFT deals. There are many tools to determine an NFT's rarity, but this is typically not the only factor in the decision to purchase. NftyThrifter allows you to look at the rarity in comparison to price to easily identify NFTs that are undervalued. Explore the top deals and use the interactive graph to find the best NFTs to buy.</Description>
                <div style={{width: '30rem', maxWidth: '100%', marginTop: '1rem'}}>
                    <Select
                        placeholder="Select Collection"
                        value={null}
                        options={collectionsMinimal}
                        onChange={(option) => {
                            navigate({
                                pathname: 'collection',
                                search: `?${createSearchParams({
                                    collectionName: option.value
                                }).toString()}`
                            })
                        }}
                        styles={{
                            control: () => ({
                                border: '1px solid lightgrey',
                                background: 'white',
                                height: '2.5rem',
                                borderRadius: '1.25rem',
                            }),
                            dropdownIndicator: () => ({
                                visibility: 'hidden',
                            }),
                            indicatorSeparator: () => ({
                                visibility: 'hidden'
                            })
                        }}
                        getOptionLabel={e => (
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                <img src={e.icon} style={{maxHeight: '2rem', maxWidth: '2rem', borderRadius: '1rem'}} />
                                <span style={{ marginLeft: 5 }}>{e.label}</span>
                            </div>
                        )}
                    />
                </div>
            </Content>

            <CollectionColumns>
                <CollectionColumn>
                        <CollectionColumnTitle>Top Price</CollectionColumnTitle>
                    {topPriceCollections.map(collection => (
                        <CollectionColumnItem onClick={() => navigate({pathname: 'collection', search: `?${createSearchParams({collectionName: collection.slug}).toString()}`})}>
                            <CollectionColumnItemImage src={collection.image_url} />
                            <CollectionItemInfoBlock>
                                <CollectionColumnItemTitle>{collection.slug}</CollectionColumnItemTitle>
                                <CollectionItemPrice><ETH />{Math.round(collection.stats.average_price*100)/100}</CollectionItemPrice>
                            </CollectionItemInfoBlock>
                        </CollectionColumnItem>
                    ))}
                </CollectionColumn>
                <CollectionColumn>
                    <CollectionColumnTitle>Top Volume</CollectionColumnTitle>
                    {topVolumeCollections.map(collection => (
                        <CollectionColumnItem onClick={() => navigate({pathname: 'collection', search: `?${createSearchParams({collectionName: collection.slug}).toString()}`})}>
                            <CollectionColumnItemImage src={collection.image_url} />
                            <CollectionItemInfoBlock>
                                <CollectionColumnItemTitle>{collection.slug}</CollectionColumnItemTitle>
                                <CollectionItemPrice><ETH />{Math.round(collection.stats.average_price*100)/100}</CollectionItemPrice>
                            </CollectionItemInfoBlock>
                        </CollectionColumnItem>
                    ))}
                </CollectionColumn>
                <CollectionColumn>
                    <CollectionColumnTitle>Top Change</CollectionColumnTitle>
                    {topChangeCollections.map(collection => (
                        <CollectionColumnItem onClick={() => navigate({pathname: 'collection', search: `?${createSearchParams({collectionName: collection.slug}).toString()}`})}>
                            <CollectionColumnItemImage src={collection.image_url} />
                            <CollectionItemInfoBlock>
                                <CollectionColumnItemTitle>{collection.slug}</CollectionColumnItemTitle>
                                <CollectionItemPrice><ETH />{Math.round(collection.stats.average_price*100)/100}</CollectionItemPrice>
                            </CollectionItemInfoBlock>
                        </CollectionColumnItem>
                    ))}
                </CollectionColumn>
            </CollectionColumns>
            
        </Page>
    )
}

export default HomePage;

const Page = styled.div`
    // position:absolute;
    // height: 100%;
    // width: 100%;
    // background: linear-gradient(180deg, #CD63FF 0%, #438EFF 100%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
`

const Header = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8vh;
    box-shadow: 0px 4px 4px 4px rgba(0,0,0,0.1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;
`

const Logo = styled.img`
    height: 80%;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    margin-top: 20vh;

    width: 100%;
    max-width: 50rem;
    border: solid white;
`

const Title = styled.h1`
    font-size: 3rem;
    color: white;

    font-family: Nexa;
    background: -webkit-linear-gradient(180deg, #CD63FF 0%, #438EFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const Description = styled.div`
    font-size: 1rem;
    color: #6d6d85;

    font-family: Nexa;
`

const CollectionColumns = styled.div`
    display: flex;
    flex-direction: row;
    
    flex-wrap: wrap;
    max-width: 100%;
    gap: 6rem 2rem;
    margin-top: 20vh;
`

const CollectionColumn = styled.div`
    display: flex;
    flex-direction: column;
`

const CollectionColumnTitle = styled.h2`
    font-size: 1.4rem;
    font-weight: bold;

    font-family: Nexa;
    background: -webkit-linear-gradient(180deg, #CD63FF 0%, #438EFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const CollectionColumnItem = styled.div`
    border-bottom: 1px solid #E5E8EB;
    padding: 1rem;
    height: 5rem;
    display: flex;
    cursor: pointer;

    &:hover {
        border-bottom: none;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.1);
    }

    transition: box-shadow 100ms ease-in-out;
`

const CollectionColumnItemTitle = styled.h2`
    font-size: 1rem;
    font-weight: bold;
    font-family: Nexa;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const CollectionColumnItemImage = styled.img`
    height: 100%;
    // width: 100%;
    border-radius: 50%;
    margin-right: 1rem;
`

const CollectionItemInfoBlock = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-start;
`

const CollectionItemPrice = styled.div`
    font-size: 1rem;
    font-weight: bold;
    color: #8F979F;
    line-height: 0.4rem;
    font-family: Nexa;
`