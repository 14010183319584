export function withCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const convertToChartData = (data) => {
    return data.map(item => ({
        'id': item.id,
        'data': [{
            'datum': item,
            'x': item.rarityScore,
            'y': item.price
        }]
    }))
}

export const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}