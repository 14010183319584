import axios from 'axios';
import { getHeaders, handleError } from './helpers';

export const fetchCollections = async () => {
    try {
        const response = await axios({
            method: 'get',
            url: 'https://aqueous-shelf-59526.herokuapp.com/https://collections.rarity.tools/collectionsStats',
            headers: getHeaders()
        });
        // Success 🎉
        const data = response.data;
        return data;


    } catch (error) {
        handleError(error);
    }
}

export const fetchCollectionsMinimal = async () => {
    try {
        const response = await axios({
            method: 'get',
            url: 'https://aqueous-shelf-59526.herokuapp.com/https://collections.rarity.tools/static/collectionsMinimal.json',
            headers: getHeaders()
        });
        // Success 🎉
        const data = response.data;
        return data.collections;


    } catch (error) {
        handleError(error);
    }
}

export const fetchCollection = async (collectionName) => {
    try {
        const response = await axios({
            method: 'get',
            url: `https://aqueous-shelf-59526.herokuapp.com/https://projects.rarity.tools/static/staticdata/${collectionName}.json`,
            headers: getHeaders()
        });
        // Success 🎉
        const data = response.data;
        // console.log(data)
        return data;


    } catch (error) {
        handleError(error);
    }
}

export const fetchCollectionConfig = async (collectionName) => {
    try {
        const response = await axios({
            method: 'get',
            url: `https://aqueous-shelf-59526.herokuapp.com/https://projects.rarity.tools/static/config/${collectionName}.json`,
            headers: getHeaders()
        });
        // Success 🎉
        const data = response.data;
        // console.log(data)
        return data;


    } catch (error) {
        handleError(error);
    }
}

export const fetchPrices = async (collectionName) => {
    try {
        const response = await axios({
            method: 'get',
            url: `https://aqueous-shelf-59526.herokuapp.com/https://data.rarity.tools/prices/${collectionName}`,
            headers: getHeaders()
        });
        // Success 🎉
        const data = response.data;
        // console.log(data.prices)
        return data.prices;


    } catch (error) {
        handleError(error);
    }
}

export const fetchAssets = async (contractAddress, id) => {
    try {
        let tokenStr;
        if (typeof id === 'object' || Array.isArray(id)) {
            tokenStr = id.reduce((str, id) => str + `&token_ids=${id}`, '')
        } else {
            tokenStr = `&token_ids=${id}`
        }

        const response = await axios({
            method: 'get',
            url: `https://aqueous-shelf-59526.herokuapp.com/https://api.opensea.io/api/v1/assets?asset_contract_address=${contractAddress}&limit=30${tokenStr}`,
            headers: getHeaders()
        });
        // Success 🎉
        const data = response.data;
        // console.log(data)
        if (data.assets.length > 1) return data.assets
        else return data.assets[0];


    } catch (error) {
        handleError(error);
    }
}