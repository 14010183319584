import { useState, useEffect, useRef, useMemo } from 'react'

import { TooltipWrapper } from '@nivo/tooltip';
import { ScaleLoader } from 'react-spinners';
import styled from 'styled-components';

import { fetchAssets } from '../../methods/requests';
import ETH from '../eth/eth';


const ItemTip = (props) => {

    const {
        node,
        bounds,
        contractAddress,
        margins
    } = props

    const { datum } = node.data; //  The actual item information
    
    const [assets, setAssets] = useState(null)
    const fetchTimeout = useRef(null)

    const retrieveAssets = async () => {
        const fetchedAssets = await fetchAssets(contractAddress, node.serieId)
        // console.log(node, fetchedAssets)
        setAssets(fetchedAssets)
    }
    useEffect(() => {
        setAssets(null)
        clearTimeout(fetchTimeout.current)
        fetchTimeout.current = setTimeout(retrieveAssets, 200)
    }, [node.serieId])

    

    // console.log(bounds, node)
    const position = useMemo(() => {
        const position = {}

        if (node.x + margins.right + 193 > bounds.width) {
            if (node.x - margins.left - 386 < 0) position.left = ((bounds.width - margins.left - margins.right)/2) - node.x - 193
            else position.left = -386 - 10
        } else if (node.x - margins.left - 193 < 0) {
            if (node.x + margins.right + 386 > bounds.width) position.left = ((bounds.width - margins.left - margins.right)/2) - node.x - 193
            else position.left = 0
        }
        else position.left = -193 - 10
    
        if (node.y - 186 < 0) position.top = 0 + 10
        else if (node.x + 193 > bounds.width) position.top = -93 - 10
        else position.top = -186 - 10

        return position;
    }, [node, margins, bounds])
    

    return (
        <TooltipContainer style={{width: '386px', height: '186px', position: 'absolute', ...position}}>
            <ImageContainer>
                {!!assets ? 
                    <ItemImage src={assets.image_preview_url}/>
                    :
                    <ScaleLoader color={'rgba(191,106,255, 0.6)'} />                    
                }
            </ImageContainer>
            <div style={{display: 'flex', flexDirection: 'column', width: '200px', overflow: 'visible'}}>
                <Metric><h2 style={{fontSize: '1.2rem', color: '#393975', fontWeight: 'bold'}}>{node.serieId}</h2></Metric>
                <Metric>
                    <MetricLabel>Price: </MetricLabel><MetricValue><ETH />{datum.price}</MetricValue>
                </Metric>
                <Metric>
                    <MetricLabel>Thrift Rank: </MetricLabel><MetricValue style={{fontWeight: 'bold'}}>#{datum.thriftyRank}</MetricValue>
                </Metric>
                <Metric>
                    <MetricLabel>Thrift Score: </MetricLabel><MetricValue>{datum.thriftyScore}</MetricValue>
                </Metric>
                <Metric>
                    <MetricLabel>Rarity Rank: </MetricLabel><MetricValue style={{fontWeight: 'bold'}}>#{datum.rarityRank}</MetricValue>
                </Metric>
                <Metric>
                    <MetricLabel>Rarity Score: </MetricLabel><MetricValue>{datum.rarityScore}</MetricValue>
                </Metric>
                {/* <Metric>
                    <MetricLabel>Proability: </MetricLabel><MetricValue>{datum.probability}</MetricValue>
                </Metric> */}
            </div>
        </TooltipContainer>
    )
}

export default ItemTip

const TooltipContainer = styled.div`
    background-color: white;
    box-shadow: 0px 4px 8px 4px rgba(200, 206, 222, 0.25);
    border-radius: 0.5rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 18px;
    gap: 18px;
    flex-grow: 1;
`

const ImageContainer = styled.div`
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ItemImage = styled.img`
    display: block;
    max-width: 150px;
    max-height: 150px;
    width: auto;
    height: auto;
`

const Metric = styled.div`
    display: flex;
    justify-content: space-between;
`

const MetricLabel = styled.h3`
    text-align: left;
    color: #8282b0;
    font-size: 0.8rem;
    font-weight: 600;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 0.8rem
`

const MetricValue = styled.h3`
    text-align: left;
    color: #8282b0;
    font-size: 0.8rem;
    line-height: 0.8rem
`