

import { ScaleLoader } from "react-spinners";
import styled from "styled-components";
import ETH from "../../eth/eth";

const ItemCard = (props) => {
    
    const {
        item,
        asset,
        onClick
    } = props;

    return (
        <Card onClick={onClick}>
            <Header>
                <Title>{item.id}</Title>
                <Bubble>#{item.thriftyRank}</Bubble>
            </Header>
            <ImageContainer>
                {asset === undefined ? 
                    <ScaleLoader color={'rgba(191,106,255, 0.6)'} /> 
                    :
                    asset === null ?
                        <></>
                        :
                        <Image src={asset.image_preview_url}/>                    
                }
            </ImageContainer>
            <Info>
                <Metric>
                    <MetricLabel>Price: </MetricLabel><MetricValue><ETH />{item.price}</MetricValue>
                </Metric>
                {/* <Metric>
                    <MetricLabel>Thrift Score: </MetricLabel><MetricValue>{item.thriftyScore}</MetricValue>
                </Metric>
                <Metric>
                    <MetricLabel>Rarity Score: </MetricLabel><MetricValue>{item.rarityScore}</MetricValue>
                </Metric> */}
                <Metric>
                    <MetricLabel>Rarity Rank: </MetricLabel><MetricValue style={{fontWeight: 'bold'}}>#{item.rarityRank}</MetricValue>
                </Metric>
            </Info>
        </Card>
    )
}

export default ItemCard

const Card = styled.div`
    background-color: white;
    box-shadow: 0px 4px 8px 4px rgba(200, 206, 222, 0.25);
    border-radius: 0.5rem;

    // height: 16rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding: 0.8rem;;
    gap: 0.5rem;

    cursor: pointer;
`

const Header = styled.div`
    width: 100%:
    height: 2rem;

    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
`

const Title = styled.div`
    font-size: 1rem;
    color: #393975;
    font-weight: bold;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Bubble = styled.div`
    font-size: 0.8rem;
    color: #393975;
    line-height: 1.2rem;
    height: 1.2rem;
    border-radius: 0.6rem;
    padding: 0 0.5rem;
    background: #e9e9f0;
`

const ImageContainer = styled.div`
    flex-grow: 1;
    position: relative;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`

const Image = styled.img`
    max-width: 100px;
    width: auto;
`

const Info = styled.div`
    display: flex;
    flex-direction:column;
    margin-top: 0.5rem;
`

const Metric = styled.div`
    display: flex;
    justify-content: space-between;
`

const MetricLabel = styled.h3`
    text-align: left;
    color: #8282b0;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 0.6rem;
`

const MetricValue = styled.h3`
    text-align: left;
    color: #8282b0;
    font-size: 0.8rem;
    line-height: 0.6rem;
`