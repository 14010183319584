export default {
    "background": "#ffffff",
    "textColor": "#c1c5e0",
    "fontSize": 14,
    "axis": {
        "domain": {
            "line": {
                "stroke": "#777777",
                "strokeWidth": 0
            }
        },
        "ticks": {
            "line": {
                "stroke": "#777777",
                "strokeWidth": 0
            }
        }
    },
    "grid": {
        "line": {
            "stroke": "#dddddd",
            "strokeWidth": 0
        }
    }
}