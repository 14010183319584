import styled from "styled-components"

const ETH = () => {
    return <ETHStyle className="fab fa-ethereum"/>
}

export default ETH

const ETHStyle = styled.i`
    font-size: 0.8rem;
    color: #393975;
    margin-right: 0.5rem;
`
