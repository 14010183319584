import { useState, useMemo, useEffect, useRef } from 'react'
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import { isMobile } from 'react-device-detect';


import ItemTip from '../itemTip/itemTip'

import theme from './theme'
import colorGrad from './colorGrad'

const ScatterPlot = (props) => {

    const {
        data,
        showAxes,
        yScaleMax,
        yScaleMin,
        contractAddress,
        onSelectItem,
        ...rest
    } = props

    const chartRef = useRef(null)
    const [bounds, setBounds] = useState({})

    const extrema = useMemo(() => data.reduce((extrema, item) => ({
        minX: item.data[0].y <= yScaleMax && item.data[0].y >= yScaleMin ? Math.min(extrema.minX, item.data[0].x) : extrema.minX,
        minY: Math.min(extrema.minY, Math.max(yScaleMin, item.data[0].y)),
        maxX: item.data[0].y <= yScaleMax && item.data[0].y >= yScaleMin ? Math.max(extrema.maxX, item.data[0].x) : extrema.maxX,
        maxY: Math.max(extrema.maxY, Math.min(yScaleMax, item.data[0].y)),
    }), {minX: Number.MAX_VALUE, minY: Number.MAX_VALUE, maxX: 0, maxY: 0}), [data, yScaleMin, yScaleMax])

    // const colors = useMemo(() => {

    //     const xScale = extrema.maxX - extrema.minX;
    //     const yScale = extrema.maxY - extrema.minY;
    //     return data.map(node => {
    //         const normRadius = Math.sqrt(Math.pow((extrema.maxX - node.data[0].x)/xScale, 2) + Math.pow((node.data[0].y - extrema.minY)/yScale, 2))
    //         const gradIdx = 39 - parseInt(39 * normRadius)
    //         return colorGrad[gradIdx]
    //     })

    // }, [data, extrema])

    const colors = useMemo(() => {
        const { maxThriftRank, minThriftRank } = data.reduce((current, item) => ({
            maxThriftRank: (item.data[0].datum.price >= yScaleMin && item.data[0].datum.price <= yScaleMax) ? Math.max(item.data[0].datum.thriftyRank, current.maxThriftRank) : current.maxThriftRank,
            minThriftRank: (item.data[0].datum.price >= yScaleMin && item.data[0].datum.price <= yScaleMax) ? Math.min(item.data[0].datum.thriftyRank, current.minThriftRank) : current.minThriftRank,
        }), {maxThriftRank: 0, minThriftRank: data[0].data[0].datum.thriftyRank})

        return data.map(item => {
            const thriftRankInterp = (item.data[0].datum.thriftyRank - minThriftRank) / (maxThriftRank - minThriftRank);
            const gradIdx = 39 - parseInt(39 * thriftRankInterp);
            return colorGrad[gradIdx];
        })
    }, [data, yScaleMin, yScaleMax])

    useEffect(() => {
        const boundingClientRect = chartRef.current.getBoundingClientRect()
        setBounds(boundingClientRect)
    }, [chartRef])

    // console.log("RENDER")
    const margins = isMobile ? { top: 10, right: 10, bottom: 10, left: 10 } : { top: 10, right: 20, bottom: 50, left: 80 }

    return (
        <div ref={chartRef} style={{height: '100%', width: '100%'}}>
            <ResponsiveScatterPlot
                theme={theme}
                data={data}
                colors={colors}
                margin={margins}
                xScale={{ type: 'linear', min: extrema.minX, max: extrema.maxX }}
                yScale={{ type: 'linear', min: yScaleMin, max: yScaleMax, stacked: false, reverse: false }}
                yFormat=" >-.3f"
                lineWidth={3}
                axisTop={null}
                axisRight={null}
                useMesh={false}
                // isInteractive={!isMobile}
                tooltip={props => isMobile ? <></> : <ItemTip {...props} contractAddress={contractAddress} bounds={bounds} margins={margins}/>}
                axisBottom={showAxes && !isMobile ? {
                    orient: 'bottom',
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Rarity',
                    legendOffset: 36,
                    legendPosition: 'middle',
                    format: rest?.xFormat
                } : null}
                axisLeft={showAxes && !isMobile ? {
                    orient: 'left',
                    tickSize: 0,
                    tickValues: 5,
                    tickPadding: 10,
                    tickRotation: 0,
                    legend: 'Price',
                    legendOffset: -60,
                    legendPosition: 'middle'
                } : null}
                enableGridX={false}
                enableGridY={false}
                enablePoints={false}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                animate={true}
                legendToggle={false}
                onClick={item => onSelectItem(item.data.datum)}
                {...rest}
            />
        </div>
    )
}

ScatterPlot.defaultProps = {
    data: [],
    showAxes: true,
    yScaleMax: 'auto',
    yScaleMin: 'auto'
}

export default ScatterPlot;