import { useRef, useState, useEffect } from 'react'

import styled from "styled-components";

import ItemCard from './itemCard/itemCard';

import { deepCopy } from '../../methods/utils';
import { fetchAssets } from '../../methods/requests';
import { ScaleLoader } from 'react-spinners';

const CardsContainer = (props) => {

    const {
        data,
        contractAddress,
        onSelectItem,
        loadAdditionalCards,
        setDoLoadAdditionalCards,
        yRange
    } = props;

    const containerRef = useRef(null)

    const [numItemsPerRow, setNumItemsPerRow] = useState(0)
    const [numRows, setNumRows] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [currentIds, setCurrentIds] = useState([])
    const [cardsData, setCardsData] = useState([])
    const [assets, setAssets] = useState({})

    const getItemAssets = async () => {
        if (!containerRef.current || !data) return
        
        const { width } = containerRef.current.getBoundingClientRect();
        const newNumItemsPerRow = Math.max(5, Math.floor((width + 20) / 260))
        const newNumRows = await loadAdditionalCards ? numRows + 3 : numRows

        const dataSlice = deepCopy(data).splice(numRows*numItemsPerRow,newNumRows*newNumItemsPerRow-numRows*numItemsPerRow)
        const dataSliceIds = dataSlice.map(data => data.id)

        if (newNumItemsPerRow !== numItemsPerRow || newNumRows !== numRows) {
            setIsLoading(true)
            
            setCurrentIds([...currentIds, ...dataSliceIds])
            setCardsData([...cardsData, ...dataSlice])
            setIsLoading(false)
            
            const newAssets = await fetchAssets(contractAddress, dataSliceIds)
            let normAssets;
            if (!!assets) {
                normAssets = {...assets, ...newAssets.reduce((normAssets, asset) => ({...normAssets, [asset.token_id]: asset}), {})}
            } else {
                normAssets = {...assets, ...dataSliceIds.reduce((normAssetPlaceholders, tokenId) => ({...normAssetPlaceholders, [tokenId]: null}), {})}
            }
            setAssets(normAssets)
        }

        // Update state last
        if (newNumItemsPerRow !== numItemsPerRow) {
            setNumItemsPerRow(newNumItemsPerRow)
        }
        if (newNumRows !== numRows) {
            setNumRows(newNumRows)
            setDoLoadAdditionalCards(false)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', getItemAssets)
        return () => {
            window.removeEventListener('resize', getItemAssets)
        }
    }, [])

    useEffect(() => {
        getItemAssets()
    }, [containerRef.current, loadAdditionalCards])

    useEffect(() => {
        // When the y range changes, unload all assets because we cant predict whats been loaded so far
        setAssets({})
        setNumRows(3)
        setCurrentIds([])
        setCardsData([])
        getItemAssets()
    }, [yRange, data])

    return (
        <Container ref={containerRef}>
            {cardsData.map((item, idx) => (
                <ItemCard item={item} asset={assets[item.id]} onClick={() => onSelectItem(item)} key={`card-${idx}`} />
            ))}
            {isLoading &&
                <ScaleLoader color={'rgba(191,106,255, 0.6)'} />
            }
        </Container>
    )
}

export default CardsContainer

const Container = styled.div`
    width: 101%;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    display: grid;
    gap: 20px;
    margin-bottom: 2rem;
`