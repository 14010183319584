import { fetchCollection, fetchPrices, fetchCollectionConfig } from './requests';

import { V2Rarity, ExperimentalRarity } from './algorithm'

const assignRarityScore = async (data) => {

    let numItems = data.items.length;
    const items = new Array(numItems);
    let itemProbability, itemRarity;
    data.items.forEach((item, itemIdx) => {
        itemProbability = 1;
        itemRarity = 0;
        for (var attrIdx=1; attrIdx<item.length; attrIdx++) {
            if (Number.isInteger(item[attrIdx]) && 'pvs' in data.basePropDefs[attrIdx]) {
                itemProbability *= data.basePropDefs[attrIdx].pvs[item[attrIdx]][1] / numItems;
                itemRarity += numItems / data.basePropDefs[attrIdx].pvs[item[attrIdx]][1]
            }
        }
        items[itemIdx] = {
            id: item[0], 
            probability: itemProbability.toPrecision(2), 
            rarityScore: Math.round(100*itemRarity)/100
        }
    })

    return items
}

const getDataFromJson = (filename) => {
    return fetch(filename, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }).then(response => {
        return response.json()
    })
}

const assignPrice = async (data, prices) => {

    return data.reduce((data, item) => {
        if (!!prices && prices[item.id]) {
            data.push({
                ...item,
                price: prices[item.id][1]
            });
        } else {
            data.push({
                ...item,
                price: NaN
            })
        }
        return data;
    }, [])
}

const assignThriftyScore = (data) => {

    return data.map(item => {
        return {
            ...item,
            thriftyScore: Math.round(100 * item.rarityScore / item.price)/100
        }
    })

}

const calculateMetrics = (data) => {
    const n = data.length

    const forSaleItems = data.filter(item => !isNaN(item.price))
    const meanPrice = forSaleItems.reduce((total, item) => total + item.price, 0) / forSaleItems.length
    // const meanPrice = forSaleItems.filter((item, idx) => idx < forSaleItems.length*3/4).reduce((total, item) => total + item.price, 0) / forSaleItems.length
    const meanRarity = forSaleItems.reduce((total, item) => total + item.rarityScore, 0) / forSaleItems.length
    const priceStd = Math.sqrt(forSaleItems.map(item => Math.pow(item.price - meanPrice, 2)).reduce((a, b) => !isNaN(b) ? a + b : a, 0) / forSaleItems.length)
    // const priceStd = Math.sqrt(forSaleItems.filter((item, idx) => idx < forSaleItems.length*3/4).map(item => Math.pow(item.price - meanPrice, 2)).reduce((a, b) => !isNaN(b) ? a + b : a, 0) / forSaleItems.length)


    return {
        floorPrice: forSaleItems.reduce((floor, item) => item.price < floor ? item.price : floor, forSaleItems[0]?.price || 0),
        ceilPrice: forSaleItems.reduce((ceil, item) => item.price > ceil ? item.price : ceil, 0),
        priceStd: Math.round(priceStd*100)/100,
        medianPrice: Math.round(forSaleItems[Math.round(forSaleItems.length/2)].price*100)/100,
        meanPrice: Math.round(meanPrice*100)/100,
        meanRarity: Math.round(meanRarity*100)/100,
        numItems: n,
        numForSaleItems: forSaleItems.length
    }
}

const assignExpectedPrice = (items, metrics) => {

    // LeastSquares best fit
    const slopeDividend = items.reduce((acc, item) => parseFloat(acc + ((item.rarityScore - metrics.meanRarity) * (item.price - metrics.meanPrice))), 0);
    const slopeDivisor = items.reduce((acc, item) => parseFloat(acc + (item.rarityScore - metrics.meanRarity) ** 2), 0);

    const slope = slopeDivisor !== 0
        ? parseFloat((slopeDividend / slopeDivisor).toFixed(4))
        : 0;

    const coeficient = parseFloat(
        (-(slope * metrics.meanRarity) + metrics.meanPrice).toFixed(2),
    );

    return items.map(item => ({...item, expectedPrice: parseFloat((slope*item.rarityScore + coeficient).toFixed(2))}))
}

export default async (collectionName) => {
    console.time('siftTimer')
    const config = await fetchCollectionConfig(collectionName)
    // console.log(config)
    const contractAddress = config.contracts[0].contract

    let data = await fetchCollection(collectionName)
    const prices = await fetchPrices(collectionName);
    
    let rarityTools = new ExperimentalRarity(data, config)
    let items = await rarityTools.run(prices);
    // console.log('total score =', items.reduce((total, item) => total += parseFloat(item.rarityScore), 0))
    // console.log(data)
    // console.log(items)

    console.log(prices)
    // items = await assignPrice(items, prices)

    let metrics = calculateMetrics(items);
    items = items.filter(item => !isNaN(item.price) && item.price < (4*metrics.medianPrice))
    if (items.length !== metrics.numForSaleItems) {
        metrics = calculateMetrics(items); // new metrics after filter out massive outliers
    }

    items = assignExpectedPrice(items, metrics)
    items = await assignThriftyScore(items)

    items = items.sort((a, b) => b.rarityScore - a.rarityScore).map((item, rarityRank) => ({...item, rarityRank: rarityRank+1}));
    items = items.sort((a, b) => b.thriftyScore - a.thriftyScore).map((item, thriftyRank) => ({...item, thriftyRank: thriftyRank+1}));

    console.timeEnd('siftTimer')
    return {data: items, metrics, contractAddress};
}