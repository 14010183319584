import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ScaleLoader } from 'react-spinners';

import styled from 'styled-components';
import { fetchAssets } from '../../methods/requests';
import ETH from '../eth/eth';

import './itemModal.css'

const modalStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.4)'
      },
      content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

        // width: 'fit-conetnte',
        // height: 'fit-content',
        height: 'fit-content',

        minWidth: '360px',
        minHeight: '360px',
        maxHeight: '90%',
        maxWidth: '90%',

        overflow: 'auto',

        padding: '2rem',
        borderRadius: '0.6rem',

        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',

        zIndex: '1000'
      }
}


const ItemModal = (props) => {

    const {
        isOpen,
        onRequestClose, 

        item,
        contractAddress,
        numTotalItems
    } = props;

    const [asset, setAsset] = useState(null)

    const fetchAsset = async () => {
        const fetchedAsset = await fetchAssets(contractAddress, item.id)
        // console.log(fetchedAsset)
        setAsset(fetchedAsset)
    }
    useEffect(() => {
        if (!!item) {
            setAsset(null)
            fetchAsset()
        }
    }, [item])
    console.log(item, item?.propPriceNorm, item?.propPriceNorm.reduce((a, b) => a+b, 0))

    return (
        <Modal  
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={modalStyle}
            ariaHideApp={false}
            closeTimeoutMS={400}
        >
            {!asset ?
                <ScaleLoader color={'rgba(191,106,255, 0.6)'} />
                :
                !item ?
                    <></>
                    :
                    <Col style={{width: '100%'}}>
                        <Row onClick={onRequestClose} style={{justifyContent: 'space-between', borderBottom: '1px solid #e9e9f0', marginBottom: '1rem'}}>
                            <Title>ID: {item.id}</Title>
                            <Bubble>#{item.thriftyRank}</Bubble>
                        </Row>
                        <Row style={{gap: '1rem', flexWrap: 'wrap'}}>
                            <ImageContainer>
                                <Image src={asset.image_preview_url} />
                            </ImageContainer>
                            <Col style={{flex: '1 0 auto'}}>
                                <Metric>
                                    <MetricLabel style={{fontSize: '1.4rem', color: '#393975'}}>Price: </MetricLabel><MetricValue style={{fontSize: '1.4rem', color: '#393975', fontWeight: 'bold'}}><ETH />{item.price}</MetricValue>
                                </Metric>
                                <Metric>
                                    <MetricLabel>Thrift Rank: </MetricLabel><MetricValue style={{fontWeight: 'bold'}}>#{item.thriftyRank}</MetricValue>
                                </Metric>
                                <Metric>
                                    <MetricLabel>Thrift Score: </MetricLabel><MetricValue>{item.thriftyScore}</MetricValue>
                                </Metric>
                                <Metric>
                                    <MetricLabel>Rarity Rank: </MetricLabel><MetricValue style={{fontWeight: 'bold'}}>#{item.rarityRank}</MetricValue>
                                </Metric>
                                <Metric>
                                    <MetricLabel>Rarity Score: </MetricLabel><MetricValue>{item.rarityScore}</MetricValue>
                                </Metric>
                                {/* <Metric>
                                    <MetricLabel>Proability: </MetricLabel><MetricValue>{item.probability}</MetricValue>
                                </Metric> */}
                                {/* <Metric>
                                    <Description>{asset?.collection?.description}</Description>
                                </Metric> */}
                                <PermaLink href={asset.permalink} target="_blank">
                                    <Icon src="https://opensea.io/static/images/logos/opensea.svg" />
                                    <span style={{color: '#2081E2', fontSize: '1.4rem', flexGrow: '1', textAlign: 'center', fontWeight: 'bold', textDecoration: 'none'}}>OpenSea.io</span>
                                </PermaLink>
                            </Col>
                        </Row>
                        <Row>
                            <TraitsContainer>
                                {asset.traits.filter(trait => trait.trait_count > 0).map(trait => {
                                    const traitPercent = trait.trait_count === 1 ?
                                        `1 of 1`
                                        :
                                        `${Math.ceil(10000*trait.trait_count / numTotalItems)/100}% have this trait`

                                    return (
                                        <Trait>
                                            <TraitName>{trait.trait_type}</TraitName>
                                            <TraitValue>{trait.value}</TraitValue>
                                            <TraitPercent>{traitPercent}</TraitPercent>
                                        </Trait>
                                    )
                                    
                                })}
                            </TraitsContainer>
                        </Row>
                    </Col>
            }
        </Modal>
    )
}

export default ItemModal;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    // width: 100%;
    // height: 100%;

    flex-wrap: wrap;
    // border: 1px solid red;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 181px;
    // height: 100%;
    // width: 100%;
    // border: 1px solid blue;
`

const Title = styled.h1`
    font-size: 1.4rem;
    color: #393975;
    font-weight: bold;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    line-height: 2rem;
`

const Bubble = styled.div`
    font-size: 1.2rem;
    color: #393975;
    line-height: 2rem;
    font-weight: bold;
    height: 2rem;
    border-radius: 1rem;
    padding: 0 1rem;
    background: #e9e9f0;
`

const ImageContainer = styled.div`
    // flex-grow: 1;
    // position: relative;
    // max-width: 16rem;
    // max-height: 16rem;
    // width: 100%;
    // max-width: 20rem;

    flex: 1 0 40%;

    display: flex;
    justify-content: center;
    align-items: center;
`

const Image = styled.img`
    // max-width: 16rem;
    // max-height: 16rem;
    width: 100%;
    height: 100%;
`

const Description = styled.p`
// width: 100%;
    font-size: 0.5rem;
`

const Metric = styled.div`
    display: flex;
    justify-content: space-between;
`

const MetricLabel = styled.h3`
    text-align: left;
    color: #8282b0;
    font-size: 1rem;
    font-weight: 600;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // line-height: 0.8rem
`

const MetricValue = styled.h3`
    text-align: left;
    color: #8282b0;
    font-size: 1rem;
    // line-height: 0.8rem
`

const PermaLink = styled.a`
    display: flex;
    border: 2px solid #2081E2;
    border-radius: 1.5rem;
    margin: 2rem 0;
    cursor: pointer;
    // height: 3rem;
    padding: 0.3rem;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
`

const Icon = styled.img`
    color: #2081E2;
    // font-size: 1.4rem;
    height: 2rem;
`

const TraitsContainer = styled.div`
    width: 100%;
    margin-top: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    display: grid;
    gap: 8px;
`

const Trait = styled.div`
    // height: 70px;
    border: 1px solid #1CB4E6;
    border-radius: 10px;
    background: #EDF8FD;

    padding: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: default;
`

const TraitName = styled.div`
    font-size: 0.6rem;
    // line-height: 0.5rem;
    color: #1CB4E6;
    font-weight: bold;
    
    text-align: center;
    overflow-wrap: break-word;
    word-break: break-all;
`

const TraitValue = styled.div`
    font-size: 0.8rem;
    // line-height: 0.5rem;
    color: black;
    font-weight: bold;

    text-align: center;
    overflow-wrap: break-word;
    word-break: break-all;
`

const TraitPercent = styled.div`
    font-size: 0.6rem;
    // line-height: 0.7rem;
    color: grey;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

`