import { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';

import styled from 'styled-components';
import logo from '../../nftythrifter_full.svg';

import ScatterPlot from '../../components/scatter/scatter';
import CardsContainer from '../../components/cardsContainer/cardsContainer';

import ScaleLoader from 'react-spinners/ScaleLoader'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import Slider, { Range, SliderTooltip } from 'rc-slider';

import { fetchCollections, fetchCollectionConfig } from '../../methods/requests';
import sift from '../../methods/sift'
import { deepCopy, withCommas, convertToChartData } from '../../methods/utils';
import ETH from '../../components/eth/eth';
import ItemModal from '../../components/itemModal/itemModal';

const { Handle } = Slider;

const SliderHandle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value}`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

const NftyThrifter = (props) => {

    const {
        collectionsMinimal: collections
    } = props

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const collectionName = searchParams.get('collectionName')

    const chartData = useRef(undefined)
    const [loadAdditionalCards, doLoadAdditionalCards] = useState(true)
    const [data, setData] = useState(undefined)
    const [inRangeData, setInRangeData] = useState(undefined)
    const [selectedItem, setSelectedItem] = useState(null)

    const [contractAddress, setContractAddress] = useState(null)
    const [metrics, setMetrics] = useState({
        numItems: 0,
        numForSaleItems: 0,
        floorPrice: 0,
        meanPrice: 0,
        priceStd: 0
    })

    const [hideChart, setHideChart] = useState(false)

    // Price slider
    const [maxPrice, setMaxPrice] = useState(100)
    const [yRange, setYRange] = useState([0, 100])
    const [sliderRange, setSliderRange] = useState(yRange)

    // Num items slider
    const [sliderValue, setSliderValue] = useState(0)
    const [numVisibleItems, setNumVisibleItems] = useState(0)


    useEffect(() => {
        if (!data || !data.length) return
        let maxPrice = 0, y;
        for (var i=0; i<numVisibleItems; i++) {
            y = data[i].price
            if (y > maxPrice) {
                maxPrice = y
            }
        }
        setMaxPrice(maxPrice)
        setSliderRange([metrics?.floorPrice || 0, maxPrice])
        setYRange([metrics?.floorPrice || 0, maxPrice])
    }, [data, numVisibleItems])

    useEffect(() => {
        if (!data) return
        const newInRangeData = deepCopy(data).filter(item => item.price >= yRange[0] && item.price <= yRange[1])
        setInRangeData(newInRangeData)
    }, [data, yRange])

    const refreshData = async () => {
        if (!collectionName) return
        setData(null)
        const { data: newData, metrics: newMetrics, contractAddress: newContractAddress } = await sift(collectionName);        
        console.log(newMetrics)
        // console.log(newData)

        // Data & Metrics
        setContractAddress(newContractAddress)
        setData(newData)
        setMetrics(newMetrics)

        // Visible items slider
        const visibleItems = Math.min(newMetrics.numForSaleItems, 200)
        setSliderValue(visibleItems)
        setNumVisibleItems(visibleItems)
    }
    useEffect(() => {
        // Refresh the data when the collection name changes in the path
        refreshData()
    }, [collectionName])

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            doLoadAdditionalCards(true)
        }
      }

    const Plot = useMemo(() => {

        chartData.current = !!data ? 
            convertToChartData(
                deepCopy(data)
                    .slice(0, numVisibleItems)
                    // .filter(item => item.price >= yRange[0] && item.price <= yRange[1])
            ) 
            : 
            undefined

        return (
            <>
                {data === null || chartData.current === null ?
                    <ScaleLoader color={'rgba(191,106,255, 0.6)'} />
                    :
                    !!data && !!chartData && chartData.current.length ?
                        hideChart ?
                            <ScaleLoader color={'rgba(191,106,255, 0.6)'} />
                            :
                            <ScatterPlot 
                                data={chartData.current} 
                                yScaleMin={yRange[0]} 
                                yScaleMax={yRange[1]} 
                                contractAddress={contractAddress}
                                onSelectItem={item => setSelectedItem(item)}
                            />
                        :
                        <>
                            <NoData>No Data
                                <div style={{color: 'lightgrey', fontWight: '100', fontSize: '0.7rem'}}>
                                    {`(Select a${!!data && !data.length ? `nother` : ``} collection)`}
                                </div>
                            </NoData>
                            
                        </>
                }
            </>
        )
    }, [data, numVisibleItems, yRange, contractAddress])
    
    return (
        <>
            <ItemModal 
                isOpen={!!selectedItem} 
                item={selectedItem} 
                onRequestClose={() => setSelectedItem(null)} 
                contractAddress={contractAddress}
                numTotalItems={metrics.numItems}
            />            

            <Container onScroll={handleScroll}>

                <Header>
                    <Logo src={logo} onClick={() => navigate('/')}/>
                </Header>
                    
                <div style={{display: 'flex', gap: '1rem', flexWrap: 'wrap'}}>
                    <Col style={{flex: '1 0 auto', minWidth: 'calc(360px - 3rem)', maxWidth: 'calc(100vw - 4rem)'}}>
                        <Card>
                            {!!collections ?
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Collection</Form.Label>
                                        <Select
                                            placeholder="Select Collection"
                                            value={collections.find(option => option.value === collectionName)}
                                            options={collections}
                                            onChange={(option) => {
                                                navigate({
                                                    search: `?${createSearchParams({
                                                        collectionName: option.value
                                                    }).toString()}`
                                                })
                                            }}
                                            getOptionLabel={e => (
                                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                                    <img src={e.icon} style={{maxHeight: '2rem', maxWidth: '2rem', borderRadius: '1rem'}} />
                                                    <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Form>
                                :
                                <ScaleLoader color={'rgba(191,106,255, 0.6)'} />
                            }
                        </Card>
                        <Card>
                            <CardLabel>Metrics</CardLabel>
                            <Metric>
                                <MetricLabel>Total Items: </MetricLabel><MetricValue>{withCommas(metrics.numItems)}</MetricValue>
                            </Metric>
                            <Metric>
                                <MetricLabel>For Sale Items: </MetricLabel><MetricValue>{withCommas(metrics.numForSaleItems)}</MetricValue>
                            </Metric>
                            <Metric>
                                <MetricLabel>Floor Price: </MetricLabel><MetricValue><ETH />{withCommas(metrics.floorPrice)}</MetricValue>
                            </Metric>
                            <Metric>
                                <MetricLabel>Mean Price: </MetricLabel><MetricValue><ETH />{withCommas(metrics.meanPrice)}</MetricValue>
                            </Metric>
                            <Metric>
                                <MetricLabel>Price STD: </MetricLabel><MetricValue><ETH />{withCommas(metrics.priceStd)}</MetricValue>
                            </Metric>
                        </Card>
                        <Card>
                            <CardLabel>Visible Items (Best {withCommas(numVisibleItems)} Deals)</CardLabel>
                            <div style={{padding: '0 1rem 0.8rem 1rem'}}>
                                <Slider 
                                    allowCross={false} 
                                    value={sliderValue} 
                                    min={1} 
                                    max={metrics.numForSaleItems} 
                                    marks={{0: 0, [metrics.numForSaleItems]: withCommas(metrics.numForSaleItems)}}
                                    handle={SliderHandle}
                                    onBeforeChange={() => setHideChart(true)}
                                    onChange={value => setSliderValue(value)}
                                    onAfterChange={value => {setNumVisibleItems(value); setHideChart(false)}}
                                />
                            </div>
                        </Card>
                        <Card style={{position: 'relative'}}>
                            <CardLabel>Price Range</CardLabel>
                            <Row style={{maxWidth: 'fit-content'}}>
                                <Col style={{flexBasis: 'calc(50% - 0.5rem)'}}>
                                    <div className="input-group" >
                                        <div className="input-group-prepend">
                                            <ETH />
                                        </div>
                                        <input 
                                            type="number" 
                                            className="form-control form-control-sm" 
                                            placeholder="Minimum" 
                                            value={sliderRange[0]} 
                                            onKeyDown={e => {
                                                if (e.keyCode === 13 || e.keyCode === 27) {
                                                    e.target.blur();
                                                }
                                            }}
                                            onChange={e => setSliderRange([e.target.value, sliderRange[1]])} 
                                            onBlur={e => setYRange([e.target.value, yRange[1]])}
                                        />
                                    </div>
                                </Col>
                                <Col style={{flexBasis: 'calc(50% - 0.5rem)'}}>
                                    <div className="input-group" >
                                        <div className="input-group-prepend">
                                            <ETH />
                                        </div>
                                        <input 
                                            type="number" 
                                            className="form-control form-control-sm" 
                                            placeholder="Maximum" 
                                            value={sliderRange[1]} 
                                            onKeyDown={e => {
                                                if (e.keyCode === 13 || e.keyCode === 27) {
                                                    e.target.blur();
                                                }
                                            }}
                                            onChange={e => setSliderRange([sliderRange[0], e.target.value])} 
                                            onBlur={e => setYRange([yRange[0], e.target.value])}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Card style={{padding: '5px', minHeight: '30rem', flex: '1 0 70%'}}>
                        {Plot}
                    </Card>
                </div>
                {!!data &&
                    <Row>
                        <HeaderLabel>Top Deals</HeaderLabel>
                        <CardsContainer 
                            loadAdditionalCards={loadAdditionalCards}
                            setDoLoadAdditionalCards={doLoadAdditionalCards}
                            data={inRangeData}
                            yRange={yRange}
                            contractAddress={contractAddress}
                            onSelectItem={item => setSelectedItem(item)} 
                        />
                    </Row>
                }
            </Container>
        </>
    )

}

const Header = styled.div`
  // position: absolute;
//   width: 100%;
  height: 10vh;

  margin: 1rem 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const Logo = styled.img`
  height: 8vh;
  cursor: pointer;
  // margin: 1vh;
`

const Container = styled.div`
    // height: fit-content;
    // width: 100%;

    // background: inherit;
    min-height: calc(90vh - 2rem);

    display: flex;
    flex-direction: column;
    padding: 0rem 2rem 0rem 2rem;
    overflow-y: auto;
    // gap: 1rem;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    flex-grow: 1;
`

const HeaderLabel = styled.h1`
    text-align: left;
    color: #393975;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 1rem;
`

const Card = styled.div`
    background-color: white;
    box-shadow: 0px 4px 8px 4px rgba(200, 206, 222, 0.25);
    border-radius: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding: 18px;
    // flex-grow: 1;
`

const CardLabel = styled.div`
    text-align: left;
    color: #393975;
    font-size: 1rem;
    height: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
`

const NoData = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 2rem;
    font-weight: bold;
    color: lightgrey;
    margin: 1rem;
`

const Metric = styled.div`
    display: flex;
    justify-content: space-between;
`

const MetricLabel = styled.h3`
    text-align: left;
    color: #8282b0;
    font-size: 0.8rem;
    font-weight: 600;
`

const MetricValue = styled.h3`
    text-align: left;
    color: #8282b0;
    font-size: 0.8rem;
`


export default NftyThrifter;