export const getHeaders = () => {

    let headers = {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    }

    return headers;
}

export const handleError = (error) => {
     // Error 😨
     if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        console.debug('error.response.data', error.response.data);
        console.debug('error.response.status', error.response.status);
        console.debug('error.response.headers', error.response.headers);
    } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.debug('error.request', error.request);
    } else {
        // Something happened in setting up the request and triggered an Error
        console.debug('error.message', error.message);
    }
    console.debug('error', error);
}