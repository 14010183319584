import { useState, useEffect } from 'react'

import './App.css';
import HomePage from './containers/homePage/homePage';
import NftyThrifter from './containers/nftyThrifter/nftyThrifter';
import styled from 'styled-components';
import { BrowserRouter, Routes, Route} from "react-router-dom"

import ReactGA from 'react-ga';

import { fetchCollections } from './methods/requests'; 

ReactGA.initialize('UA-215804723-1');
ReactGA.pageview(window.location.pathname);


function App() {

  const [collectionsMinimal, setCollectionsMinimal] = useState(null)
  const [collections, setCollections] = useState(null)

  const fetchAndSetCollections = async () => {
    const newCollections = await fetchCollections()
    const formattedCollections = newCollections.map(collection => ({label: collection.slug, value: collection.slug, icon: collection.image_url}))
    setCollections(newCollections)
    setCollectionsMinimal(formattedCollections)
  }

  useEffect(() => {
    fetchAndSetCollections()
  }, [])

  return (
    <BrowserRouter >
      <Page className="App">
        <Routes>
          <Route path='/' element={<HomePage collectionsMinimal={collectionsMinimal} collections={collections}/>} />
          <Route path='/collection' element={<NftyThrifter collectionsMinimal={collectionsMinimal} />} />
        </Routes>
      </Page>
    </BrowserRouter>
  );
}

export default App;

const Page = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
`

